<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <b-col
      v-if="$route.meta.name == 'dashboard-views-edit'"
      class="mb-2"
      cols="12"
      md="12"
    >
      <b-alert
        show
      >
        <div class="alert-body dashboard__edit-alert">
          <strong>Attention!</strong><span class="fs-14"> You’re now editing the dashboard view, and this will affect the users tagged to this dashboard.</span>
        </div>
      </b-alert>
    </b-col>

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h3 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                :to="resolveBaseURL"
                :class="getActiveClass"
                :disabled="baseRoutes.includes($route.name)"
              >
                <feather-icon
                  :icon="getIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in getBreadcrumbs"
                :key="item.text"
                :active="item.active"
                :to="resolveURL(item.to)"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import { HomeIcon } from 'vue-feather-icons'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BAlert,
  },
  data() {
    return {
      icon: 'HomeIcon',
      breadcrumbs: this.$route.meta.breadcrumb,
      baseRoutes: ['directory-users-index', 'directory-departments-index', 'directory-business-entities-index',
        'directory-addresses-index', 'directory-teams-index', 'directory-roles-index', 'directory-app-clients-index', 'appointment-activity-types-index', 'appointment-calendar-index', 'appointment-events-index',
        'appointment-bookings-index', 'appointment-call-logs-index', 'appointment-call-log-types-index', 'operation-roster-groups-index', 'operation-roster-status-types-index',
        'operation-roster-annual-groupings-index', 'operation-roster-monthly-index', 'operation-task-block-components-index', 'operation-workflow-category-index',
        'operation-workflows-index', 'operation-service-descriptions', 'operation-service-forms-index', 'operation-service-requests-index', 'purchasing-purchase-requests-index',
        'purchasing-my-purchase-requests-index', 'purchasing-vendors-index', 'purchasing-items-master-index', 'inventory-stock-list',
        'inventory-category-list', 'inventory-store-list', 'inventory-inventory-items-list', 'budgeting-budget-overview', 'dashboard', 'dashboard-views-index',
        'app-contact-information-index', 'app-highlights-index', 'app-customer-profile-update-index', 'customers-files-index', 'customers-customers-index'],
    }
  },
  computed: {
    getActiveClass() {
      let activeClass = ''
      if (this.baseRoutes.includes(this.$route.name)) {
        activeClass = 'active'
      }
      return activeClass
    },
    getBreadcrumbs() {
      return store.state.breadcrumb.breadcrumbs
    },
    getIcon() {
      let icon = 'HomeIcon'
      if (this.$route.meta.pageTitle === 'Purchasing') {
        icon = 'ShoppingBagIcon'
      } else if (this.$route.meta.pageTitle === 'Customers') {
        icon = 'UsersIcon'
      } else if (this.$route.meta.pageTitle === 'Appointment') {
        icon = 'CalendarIcon'
      } else if (this.$route.meta.pageTitle === 'Directory') {
        icon = 'FileIcon'
      } else if (this.$route.meta.pageTitle === 'Operations') {
        icon = 'ClipboardIcon'
      } else if (this.$route.meta.pageTitle === 'Inventory') {
        icon = 'PackageIcon'
      } else if (this.$route.meta.pageTitle === 'Budgeting') {
        icon = 'DollarSignIcon'
      } else if (this.$route.meta.pageTitle === 'Dashboard') {
        icon = 'GridIcon'
      } else if (this.$route.meta.pageTitle === 'Content') {
        icon = 'LayoutIcon'
      }

      return icon
    },
    resolveBaseURL() {
      let url = ''

      if (this.baseRoutes.includes(this.$route.name)) {
        url = ''
      } else if (this.$route.meta.pageTitle === 'Purchasing') {
        url = { name: 'purchasing-purchase-requests-index', params: { type: 'all-requests' } }
      } else if (this.$route.meta.pageTitle === 'Customers') {
        url = { name: 'customers-files-index' }
      } else if (this.$route.meta.pageTitle === 'Appointment') {
        url = { name: 'appointment-calendar-index' }
      } else if (this.$route.meta.pageTitle === 'Directory') {
        url = { name: 'directory-users-index' }
      } else if (this.$route.meta.pageTitle === 'Operations') {
        url = { name: 'operation-service-forms-index', params: { type: 'all-service-forms' } }
      } else if (this.$route.meta.pageTitle === 'Inventory') {
        url = { name: 'inventory-stock-list' }
      } else if (this.$route.meta.pageTitle === 'Budgeting') {
        url = { name: 'budgeting-budget-overview' }
      } else if (this.$route.meta.pageTitle === 'Dashboard') {
        url = { name: 'dashboard' }
      } else if (this.$route.meta.pageTitle === 'Content') {
        url = { name: 'app-contact-information-index' }
      }

      return url
    },
  },
  // watch: {
  //   $route: {
  //     handler(value) {
  //       console.log(value.meta.breadcrumb)
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  methods: {
    resolveURL(url) {
      if (url && url.includes(':parent')) {
        const update = url.replace(':parent', this.$route.params.parent)
        return update
      }
      return url
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard__edit-alert{
     background-color: rgb(254 198 176 / 98%);
     color: #FF9F43;
     span{
        font-weight: 400;
     }
}
</style>
