/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import app from './app'
import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
import purchase from './purchase'
import operation from './operation'
import appointment from './appointment'
import directory from './directory'
import inventory from './inventory'
import budget from './budget'
import customer from './customer'

// Array of sections
export default [...purchase, ...operation, ...directory, ...appointment, ...inventory, ...budget, ...dashboard, ...app, ...customer]
