export default [
  {
    title: 'Contact Information',
    route: 'app-contact-information-index',
    icon: 'ListIcon',
    parent: 'content',
    resource: 'ContactInformation',
    action: 'read',
  },
  {
    title: 'Highlights',
    route: 'app-highlights-index',
    icon: 'StarIcon',
    parent: 'content',
    resource: 'Highlights',
    action: 'read',
  },
]
