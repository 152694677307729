export default [
  {
    title: 'Purchasing',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Purchase Requests',
        icon: 'InboxIcon',
        parent: 'purchasing',
        children: [
          {
            title: 'In Request',
            route: 'purchasing-purchase-requests-index',
            icon: '',
            params: {
              type: 'in-request',
            },
            resource: 'PurchaseRequest',
            action: 'read',
          },
          {
            title: 'In Quotation',
            route: 'purchasing-purchase-requests-index',
            icon: '',
            params: {
              type: 'in-quotation',
            },
            resource: 'PurchaseRequest',
            action: 'read',
          },
          {
            title: 'In Purchase Order',
            route: 'purchasing-purchase-requests-index',
            icon: '',
            params: {
              type: 'in-order',
            },
            resource: 'PurchaseRequest',
            action: 'read',
          },
          {
            title: 'In Delivery',
            route: 'purchasing-purchase-requests-index',
            icon: '',
            params: {
              type: 'in-delivery',
            },
            resource: 'PurchaseRequest',
            action: 'read',
          },
          {
            title: 'All Purchase Requests',
            route: 'purchasing-purchase-requests-index',
            icon: '',
            params: {
              type: 'all-requests',
            },
            resource: 'PurchaseRequest',
            action: 'read',
          },
          {
            title: '',
            route: '',
            icon: '',
          },
          {
            title: 'Delivery Receipts',
            route: 'purchasing-delivery-receipts-index',
            icon: '',
            params: {
              type: 'all-receipts',
            },
            resource: 'DeliveryReceipt',
            action: 'read',
          },
          {
            title: 'Non-Conformance Forms',
            route: 'purchasing-ncf-index',
            resource: 'NonConformanceForm',
            icon: '',
            params: {
              type: 'all-ncfs',
            },
            action: 'read',
          },
        ],
      },
      {
        title: 'My Purchase Requests',
        icon: 'BoxIcon',
        parent: 'purchasing',
        children: [
          {
            title: 'My Purchase Requests',
            route: 'purchasing-my-purchase-requests-index',
            icon: '',
            params: {
              type: 'all-requests',
            },
            resource: 'MyPurchaseRequest',
            action: 'my-request-read',
          },
          {
            title: 'My Delivery Receipts',
            route: 'purchasing-my-delivery-receipts-index',
            icon: '',
            params: {
              type: 'all-receipts',
            },
            resource: 'DeliveryReceipt',
            action: 'my-delivery-receipt-read',
          },
          {
            title: 'My Non-Conformance Forms',
            route: 'purchasing-my-ncf-index',
            icon: '',
            params: {
              type: 'all-ncfs',
            },
            resource: 'NonConformanceForm',
            action: 'my-ncf-read',
          },
          {
            title: 'My Vendor Payments',
            route: 'purchasing-my-vendor-payments-index',
            icon: '',
            resource: 'MyVendorPayment',
            action: 'my-vp-read',
          },
          {
            title: '',
            route: '',
            icon: '',
          },
          {
            title: 'New Purchase Request',
            route: 'purchasing-purchase-requests-create',
            icon: '',
            resource: 'MyPurchaseRequest',
            action: 'my-request-create',
          },
          {
            title: 'New Delivery Receipt',
            route: 'purchasing-delivery-receipts-create',
            icon: '',
            resource: 'DeliveryReceipt',
            action: 'create',
          },
          {
            title: 'New Non-Conformance',
            route: 'purchasing-ncf-create',
            icon: '',
            resource: 'NonConformanceForm',
            action: 'create',
          },
        ],
      },
      {
        title: 'Vendor Payments',
        icon: 'CreditCardIcon',
        route: 'purchasing-vendor-payments-index',
        parent: 'purchasing',
        resource: 'VendorPayment',
        action: 'read',
      },
      {
        title: 'Vendors',
        icon: 'UsersIcon',
        parent: 'purchasing',
        children: [
          {
            title: 'Vendor List',
            route: 'purchasing-vendors-index',
            icon: '',
            params: {
              type: 'all-vendors',
            },
            resource: 'Vendor',
            action: 'read',
          },
          {
            title: 'Evaluation Reports',
            route: 'purchasing-vendors-evaluations',
            // route: '',
            icon: '',
            resource: 'Vendor',
            action: 'generate-evaluation',
          },
          {
            title: '',
            route: '',
            icon: '',
          },
          {
            title: 'Create New Vendor',
            route: 'purchasing-vendors-create',
            resource: 'Vendor',
            icon: '',
            action: 'create',
          },
        ],
      },
      {
        title: 'Items Masterlist',
        icon: 'ListIcon',
        route: 'purchasing-items-master-index',
        parent: 'purchasing',
        resource: 'ItemsMaster',
        action: 'read',
      },
    ],
  },
]
